#home {
  margin-top: 85px;
  height: calc(100vh - 85px);
}

.carousel {
  height: calc(100vh - 85px);

  #overlay {
    position: absolute; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(5px);
  }
}

.carousel-inner,
.carousel-item {
  height: 100%;
}

.carousel-item img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}
