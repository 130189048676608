$light-gray: rgb(230, 230, 230);
$dark-grey: #212529;
$orange: rgba(255, 125, 39, 1);
$orange-gradient: linear-gradient(270deg, rgba(255, 125, 39, 1) 0%, rgba(0, 166, 243, 1) 72.92%);

#about {
  position: relative;
  z-index: 99;
  max-width: 100vw !important;
  margin-top: 5px;
  margin-bottom: 150px;

  .content {
    position: relative;
    top: 5%;
    max-width: 100vw;
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }

  .img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about-img {
    position: relative;
    width: 500px;
    height: 500px;
    border: 0.188rem solid $orange;
    padding: 0.125rem;
    border-radius: 50%;
    object-fit: cover;
  }

  .header {
    font-weight: 700;
    margin: 0 0 0.625rem;
    color: white !important;
    font-weight: 700;
    font-size: 3.5rem;
    text-transform: uppercase;
    color: white;
    background: $orange;
    padding: 1rem 1rem;
    border-radius: 5px !important;
    max-width: 500px;
    margin-top : 3rem;
  }

  .about-text {
    position: relative;
    font-weight: 500;
    font-size: 22px;
  }
  .about-header {
    font-weight: 700;
  }

  .text-wrapper {
    color: white;
    background: $dark-grey;
    padding: 1rem 1rem;
    border-radius: 5px !important;
    max-width: 600px;
    margin-bottom: 10px;
  }

  .cus-link {
    color: white;
  }

  @media (max-width: 768px) {
    .about-img {
      width: 250px;
      height: 250px;
    }
    .about-text {
      font-size: 22px;
    }

    .header {
      font-size: 2rem;
    }
  }
}

@media (max-width: 768px){
  #about {
    margin-bottom: 0;
    margin-top: 30px;
  }
}
