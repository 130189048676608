$orange: rgba(255, 125, 39, 1);

#accessory {
  position: relative;
  z-index: 99;
  max-width: 100vw !important;
  margin-top: 85px;

  .accessory-wrapper {
    position: relative;
    margin-top: 8% !important;
  }

  .d-img-wrapper {
    margin-bottom: 3rem;
  }

  @media (max-width: 1900px) {
    .accessory-wrapper {
      margin-top: 15% !important;
    }
  }
  @media (max-width: 1024px) {
    .accessory-wrapper {
      margin-top: 20% !important;
    }

    .image {
      width: 550px;
      height: auto;
    }
  }

  @media (max-width: 768px) {
    .accessory-wrapper {
      margin-top: 30% !important;
    }

    .image {
      width: 550px;
      height: auto;
    }
  }

  @media (max-width: 640px) {
    .accessory-wrapper {
      margin-top: 40% !important;
    }

    .image {
      width: 450px;
      height: auto;
    }
  }

  @media (max-width: 550px) {
    .accessory-wrapper {
      margin-top: 50% !important;
    }

    .image {
      width: 350px;
      height: auto;
    }
  }

  @media (max-width: 280px) {
    .accessory-wrapper {
      margin-top: 60% !important;
    }
  }

  .header {
    position: relative;
    width: 90vw;
    left: 5vw;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 32px;
    text-transform: uppercase;
    border-bottom: 3px solid $orange;
  }

  .row {
    position: relative;
    width: 90vw;
    left: 5.5vw;
  }
}