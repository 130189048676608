$light-gray: rgb(230, 230, 230);
$orange-gradient: linear-gradient(
  270deg,
  rgba(255, 125, 39, 1) 0%,
  rgb(255, 255, 255) 72.92%
);
$blue-gradient: linear-gradient(to left, #00a6f3, white);
$blue: rgba(0, 166, 243, 1); 
$orange: rgba(255, 125, 39, 1);
$dark-grey: #212529bb;

#contact {
  background-color: white;
  z-index: 99;
  max-width: 100vw !important;
  margin-top: 85px;
  height: calc(100vh - 85px);
  overflow-x: hidden;

  .contact-wrapper {
    position: relative;
    top: 14%;
    padding: 10px 10px 10px 10px;
  }

  .contact-header {
    font-weight: 700;
    margin: 0 0 0.625rem;
    color: white !important;
    font-weight: 700;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: white;
    background: $orange;
    padding: 1rem 1rem;
    border-radius: 5px !important;
    max-width: 400px;
    margin-top : 3rem;
  }

  input,
  textarea {
    width: 100%;
    box-sizing: border-box;
    background: #E8F0FE;
    resize: none;
    border: 0;
    border-bottom: 0.125rem transparent;
    border-radius: 0;
    color: $blue;
    outline: 0 !important;
    box-shadow: none !important;
    margin-bottom: 1rem;
    padding-left: 0 !important;
  }

  input.form-control,
  textarea.form-control {
    margin: -0.25rem 0;
  }

  textarea {
    height: 12.1rem !important;
  }

  .container {
    padding: 4rem 0;
  }

  input:focus,
  textarea:focus {
    color: $blue;
  }

  .contacts-btn {
    margin: 1rem 0 0 0;
    width: 100%;
    background: none;
  }
  .line {
    position: relative;
    width: 0;
    top: -0.8rem;
    height: 0.125rem;
    background: $orange;
    display: inline-block;
  }

  input:focus + .line,
  textarea:focus + .line {
    width: 100%;
    transition: width 0.5s ease-in-out;
  }

  .contact-btn {
    border: 1px solid $orange;
    width: 100%;
    text-transform: uppercase;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.5rem;
    padding: 0.55rem 0.55rem 0.55rem 0.55rem;
    color: $orange;
    background: white;
    margin-top: 1.8rem;
    cursor: pointer;
  }

  .contact-btn:hover {
    text-decoration: none;
    background: $orange;
    color: white;
    transition: 0.2s ease-in-out;
  }

  @media (max-width:768px) {
    .contact-header {
      font-size: 2rem;
    }
  }

  //VALIDATION

  .error-message {
    color: white;
    background: $orange;
    text-align: left;
  }

  .success-message {
    color: green;
  }
}
