$dark-grey: rgba(169, 169, 169, 1);
$light-gray: rgba(169, 169, 169, 1);
$dark-orange: rgba(240, 96, 0, 1);
$orange: rgba(255, 125, 39, 1);
$blue: rgba(0, 166, 243, 1);
$blue-gradient: linear-gradient(to left, #00a6f3, white);

//*******************NAVBAR*********************//

.navbar {
  background: $blue-gradient;
  position: fixed;
  width: 100%;
  height: 85px;
  padding-left: 5%;
  padding-right: 5%;
  font-weight: 700 !important;
  font-size: 1rem !important;

  .nav-container {
    width: 100% !important;
  }

  //*****************BRAND**********************//

  .navbar-logo {
    padding: 0px !important;
  }

  #logo {
    width: auto;
    height: 59px;
  }

  #logo-offcanvas {
    width: auto;
    height: 54px;
  }

  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 1200px) {
    #logo {
      width: auto;
      height: 50px;
    }
  }

  @media (max-width: 515px) {
    #logo {
      width: auto;
      height: 35px;
    }

    .navbar-toggle .icon-bar {
      width: 10px !important;
      height: 3px !important;
    }
  }

  @media (max-width: 420px) {
    #logo {
      display: none;
    }
  }

  .small-logo {
    display: none;
    width: auto;
    height: 59px;
  }

  @media (max-width: 420px) {
    .small-logo {
      display: initial;
    }
  }

  @media (max-width: 310px) {
    .small-logo {
      display: none;
    }
  }

  //*********************NAV-LINKS*****************//

  @media (max-width: 515px) {
    .hamburger {
      max-width: 50px !important;
    }

    .burger-icon {
      width: 25px !important;
    }
  }

  .nav-links {
    font-size: 18px;
    margin-right: 20px;
    color: black !important;
  }

  @media (max-width: 768px) {
    .nav-links {
      font-size: 24px;
    }
  }
  .nav-link {
    border: 0.1875rem solid transparent;
  }
  .nav-link.active,
  .nav-link:focus,
  .nav-link:hover {
    border-bottom: 0.1875rem solid $orange;
  }

  @media (min-width: 1200px) {
    .dropdown {
      .dropdown-menu-center {
        right: auto;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
      }
    }
  }

  .dropdown {
    .dropdown-menu {
      .dropdown-item {
        color: black;
        font-weight: 500;
        font-size: 18px;
        border: 0.1875rem solid transparent;
        text-transform: uppercase;
      }

      .dropdown-item.active,
      .dropdown-item:focus,
      .dropdown-item:hover {
        border-bottom: 0.1875rem solid $orange;
        transition: ease-in-out 0.2s;
      }
    }
  }

  .navbar-nav li:hover>.dropdown-menu {
    display: block;
    margin-top: 0px;
  }

  .navbar-nav li>.dropdown-menu {
    display: none;
    margin-top: 0px;
  }
}
