$orange: rgba(255, 125, 39, 1);

#series {
  z-index: 99;
  max-width: 100vw !important;
  margin-top: 85px;


  .series-wrapper {
    position: relative;
    margin-top: 8% !important;
  }

  @media (max-width: 1900px) {
    .series-wrapper {
      margin-top: 15% !important;
    }
  }
  @media (max-width: 1024px) {
    .series-wrapper {
      margin-top: 20% !important;
    }

    .image {
      width: 550px;
      height: auto;
    }
  }

  @media (max-width: 768px) {
    .series-wrapper {
      margin-top: 30% !important;
    }

    .image {
      width: 550px;
      height: auto;
    }
  }

  @media (max-width: 640px) {
    .series-wrapper {
      margin-top: 40% !important;
    }

    .image {
      width: 450px;
      height: auto;
    }
  }

  @media (max-width: 550px) {
    .series-wrapper {
      margin-top: 50% !important;
    }

    .image {
      width: 350px;
      height: auto;
    }
  }

  @media (max-width: 280px) {
    .series-wrapper {
      margin-top: 60% !important;
    }
  }

  .header {
    position: relative;
    width: 90vw;
    left: 5vw;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 32px;
    text-transform: uppercase;
    border-bottom: 3px solid $orange;
  }

  .sub-header {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    left: 5vw;
    width: 90vw;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    border-bottom: 3px solid $orange;
  }

  .line {
    position: relative;
    width: 40vw;
    top: -0.8rem;
    height: 3px;
    background: $orange;
    display: inline-block;
    margin-top: 1.7rem;
  }

  .row {
    position: relative;
    width: 90vw;
    left: 5.5vw;
  }

  .table-img-2{
    margin-bottom: 3rem;
  }

  @media (max-width:768px) {
    .row {
      left: 8vw;
    }
  }

  .img-container {
    max-width: 100%;
  }

  .table-img {
    width: 600px;
  }
  .table-img-2 {
    width: 800px;
  }

  @media (max-width: 768px) {
    .table-img {
      width: 300px;
    }

    .table-img-2 {
        width: 300px;
      }
  }

  .table-container {
    max-width: 100% !important;

    white-space: nowrap;

    .t-heading {
      font-size: 14px;
    }
  
    .t-content {
      font-size: 12px;
    }
  }

  
}
